.sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(eye-animation.png);
}

.ECHT-NAAR-SLECHT0000 {
  width: 124px;
  height: 118px;
  background-position: -393px -3px;
}
.ECHT-NAAR-SLECHT0001 {
  width: 124px;
  height: 118px;
  background-position: -521px -3px;
}
.ECHT-NAAR-SLECHT0002 {
  width: 126px;
  height: 118px;
  background-position: -3px -3px;
}
.ECHT-NAAR-SLECHT0003 {
  width: 126px;
  height: 118px;
  background-position: -133px -3px;
}
.ECHT-NAAR-SLECHT0004 {
  width: 124px;
  height: 118px;
  background-position: -649px -3px;
}
.ECHT-NAAR-SLECHT0005 {
  width: 124px;
  height: 118px;
  background-position: -777px -3px;
}
.ECHT-NAAR-SLECHT0006 {
  width: 124px;
  height: 118px;
  background-position: -905px -3px;
}
.ECHT-NAAR-SLECHT0007 {
  width: 122px;
  height: 118px;
  background-position: -1795px -125px;
}
.ECHT-NAAR-SLECHT0008 {
  width: 118px;
  height: 118px;
  background-position: -2171px -125px;
}
.ECHT-NAAR-SLECHT0009 {
  width: 112px;
  height: 118px;
  background-position: -2413px -125px;
}
.ECHT-NAAR-SLECHT0010 {
  width: 104px;
  height: 118px;
  background-position: -2645px -125px;
}
.ECHT-NAAR-SLECHT0011 {
  width: 92px;
  height: 118px;
  background-position: -2861px -125px;
}
.ECHT-NAAR-SLECHT0012 {
  width: 78px;
  height: 118px;
  background-position: -3081px -3px;
}
.ECHT-NAAR-SLECHT0013 {
  width: 78px;
  height: 118px;
  background-position: -3047px -125px;
}
.ECHT-NAAR-SLECHT0014 {
  width: 86px;
  height: 118px;
  background-position: -2957px -125px;
}
.ECHT-NAAR-SLECHT0015 {
  width: 104px;
  height: 118px;
  background-position: -2753px -125px;
}
.ECHT-NAAR-SLECHT0016 {
  width: 112px;
  height: 118px;
  background-position: -2529px -125px;
}
.ECHT-NAAR-SLECHT0017 {
  width: 116px;
  height: 118px;
  background-position: -2293px -125px;
}
.ECHT-NAAR-SLECHT0018 {
  width: 120px;
  height: 118px;
  background-position: -2047px -125px;
}
.ECHT-NAAR-SLECHT0019 {
  width: 122px;
  height: 118px;
  background-position: -1921px -125px;
}
.ECHT-NAAR-SLECHT0020 {
  width: 124px;
  height: 118px;
  background-position: -1033px -3px;
}
.ECHT-NAAR-SLECHT0021 {
  width: 124px;
  height: 118px;
  background-position: -1161px -3px;
}
.ECHT-NAAR-SLECHT0022 {
  width: 126px;
  height: 118px;
  background-position: -263px -3px;
}
.ECHT-NAAR-SLECHT0023 {
  width: 124px;
  height: 118px;
  background-position: -1289px -3px;
}
.ECHT-NAAR-SLECHT0024 {
  width: 124px;
  height: 118px;
  background-position: -1417px -3px;
}
.ECHT-NAAR-SLECHT0025 {
  width: 124px;
  height: 118px;
  background-position: -1545px -3px;
}
.ECHT-NAAR-SLECHT0026 {
  width: 124px;
  height: 118px;
  background-position: -1673px -3px;
}
.ECHT-NAAR-SLECHT0027 {
  width: 124px;
  height: 118px;
  background-position: -1801px -3px;
}
.ECHT-NAAR-SLECHT0028 {
  width: 124px;
  height: 118px;
  background-position: -1929px -3px;
}
.ECHT-NAAR-SLECHT0029 {
  width: 124px;
  height: 118px;
  background-position: -2057px -3px;
}
.ECHT-NAAR-SLECHT0030 {
  width: 124px;
  height: 118px;
  background-position: -2185px -3px;
}
.ECHT-NAAR-SLECHT0031 {
  width: 124px;
  height: 118px;
  background-position: -2313px -3px;
}
.ECHT-NAAR-SLECHT0032 {
  width: 124px;
  height: 118px;
  background-position: -2441px -3px;
}
.ECHT-NAAR-SLECHT0033 {
  width: 124px;
  height: 118px;
  background-position: -2569px -3px;
}
.ECHT-NAAR-SLECHT0034 {
  width: 124px;
  height: 118px;
  background-position: -2697px -3px;
}
.ECHT-NAAR-SLECHT0035 {
  width: 124px;
  height: 118px;
  background-position: -2825px -3px;
}
.ECHT-NAAR-SLECHT0036 {
  width: 124px;
  height: 118px;
  background-position: -2953px -3px;
}
.ECHT-NAAR-SLECHT0037 {
  width: 124px;
  height: 118px;
  background-position: -3px -125px;
}
.ECHT-NAAR-SLECHT0038 {
  width: 124px;
  height: 118px;
  background-position: -131px -125px;
}
.ECHT-NAAR-SLECHT0039 {
  width: 124px;
  height: 118px;
  background-position: -259px -125px;
}
.ECHT-NAAR-SLECHT0040 {
  width: 124px;
  height: 118px;
  background-position: -387px -125px;
}
.ECHT-NAAR-SLECHT0041 {
  width: 124px;
  height: 118px;
  background-position: -515px -125px;
}
.ECHT-NAAR-SLECHT0042 {
  width: 124px;
  height: 118px;
  background-position: -643px -125px;
}
.ECHT-NAAR-SLECHT0043 {
  width: 124px;
  height: 118px;
  background-position: -771px -125px;
}
.ECHT-NAAR-SLECHT0044 {
  width: 124px;
  height: 118px;
  background-position: -899px -125px;
}
.ECHT-NAAR-SLECHT0045 {
  width: 124px;
  height: 118px;
  background-position: -1027px -125px;
}
.ECHT-NAAR-SLECHT0046 {
  width: 124px;
  height: 118px;
  background-position: -1155px -125px;
}
.ECHT-NAAR-SLECHT0047 {
  width: 124px;
  height: 118px;
  background-position: -1283px -125px;
}
.ECHT-NAAR-SLECHT0048 {
  width: 124px;
  height: 118px;
  background-position: -1411px -125px;
}
.ECHT-NAAR-SLECHT0049 {
  width: 124px;
  height: 118px;
  background-position: -1539px -125px;
}
.ECHT-NAAR-SLECHT0050 {
  width: 124px;
  height: 118px;
  background-position: -1667px -125px;
}

@media (min-resolution: 2dppx) {
  .sprite {
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url(eye-animation@2x.png);
  }

  .ECHT-NAAR-SLECHT0000 {
    width: 248px;
    height: 198px;
    background-position: -511px -3px;
  }
  .ECHT-NAAR-SLECHT0001 {
    width: 248px;
    height: 198px;
    background-position: -763px -3px;
  }
  .ECHT-NAAR-SLECHT0002 {
    width: 250px;
    height: 198px;
    background-position: -3px -3px;
  }
  .ECHT-NAAR-SLECHT0003 {
    width: 248px;
    height: 198px;
    background-position: -1015px -3px;
  }
  .ECHT-NAAR-SLECHT0004 {
    width: 248px;
    height: 198px;
    background-position: -1267px -3px;
  }
  .ECHT-NAAR-SLECHT0005 {
    width: 248px;
    height: 198px;
    background-position: -1519px -3px;
  }
  .ECHT-NAAR-SLECHT0006 {
    width: 246px;
    height: 198px;
    background-position: -3px -811px;
  }
  .ECHT-NAAR-SLECHT0007 {
    width: 240px;
    height: 198px;
    background-position: -751px -811px;
  }
  .ECHT-NAAR-SLECHT0008 {
    width: 234px;
    height: 198px;
    background-position: -1239px -811px;
  }
  .ECHT-NAAR-SLECHT0009 {
    width: 224px;
    height: 198px;
    background-position: -1715px -811px;
  }
  .ECHT-NAAR-SLECHT0010 {
    width: 206px;
    height: 198px;
    background-position: -2275px -3px;
  }
  .ECHT-NAAR-SLECHT0011 {
    width: 182px;
    height: 198px;
    background-position: -2271px -407px;
  }
  .ECHT-NAAR-SLECHT0012 {
    width: 156px;
    height: 198px;
    background-position: -2169px -811px;
  }
  .ECHT-NAAR-SLECHT0013 {
    width: 154px;
    height: 198px;
    background-position: -2329px -811px;
  }
  .ECHT-NAAR-SLECHT0014 {
    width: 172px;
    height: 198px;
    background-position: -2271px -609px;
  }
  .ECHT-NAAR-SLECHT0015 {
    width: 206px;
    height: 198px;
    background-position: -2271px -205px;
  }
  .ECHT-NAAR-SLECHT0016 {
    width: 222px;
    height: 198px;
    background-position: -1943px -811px;
  }
  .ECHT-NAAR-SLECHT0017 {
    width: 234px;
    height: 198px;
    background-position: -1477px -811px;
  }
  .ECHT-NAAR-SLECHT0018 {
    width: 240px;
    height: 198px;
    background-position: -995px -811px;
  }
  .ECHT-NAAR-SLECHT0019 {
    width: 244px;
    height: 198px;
    background-position: -503px -811px;
  }
  .ECHT-NAAR-SLECHT0020 {
    width: 246px;
    height: 198px;
    background-position: -253px -811px;
  }
  .ECHT-NAAR-SLECHT0021 {
    width: 248px;
    height: 198px;
    background-position: -1771px -3px;
  }
  .ECHT-NAAR-SLECHT0022 {
    width: 248px;
    height: 198px;
    background-position: -2023px -3px;
  }
  .ECHT-NAAR-SLECHT0023 {
    width: 250px;
    height: 198px;
    background-position: -257px -3px;
  }
  .ECHT-NAAR-SLECHT0024 {
    width: 248px;
    height: 198px;
    background-position: -3px -205px;
  }
  .ECHT-NAAR-SLECHT0025 {
    width: 248px;
    height: 198px;
    background-position: -255px -205px;
  }
  .ECHT-NAAR-SLECHT0026 {
    width: 248px;
    height: 198px;
    background-position: -507px -205px;
  }
  .ECHT-NAAR-SLECHT0027 {
    width: 248px;
    height: 198px;
    background-position: -759px -205px;
  }
  .ECHT-NAAR-SLECHT0028 {
    width: 248px;
    height: 198px;
    background-position: -1011px -205px;
  }
  .ECHT-NAAR-SLECHT0029 {
    width: 248px;
    height: 198px;
    background-position: -1263px -205px;
  }
  .ECHT-NAAR-SLECHT0030 {
    width: 248px;
    height: 198px;
    background-position: -1515px -205px;
  }
  .ECHT-NAAR-SLECHT0031 {
    width: 248px;
    height: 198px;
    background-position: -1767px -205px;
  }
  .ECHT-NAAR-SLECHT0032 {
    width: 248px;
    height: 198px;
    background-position: -2019px -205px;
  }
  .ECHT-NAAR-SLECHT0033 {
    width: 248px;
    height: 198px;
    background-position: -3px -407px;
  }
  .ECHT-NAAR-SLECHT0034 {
    width: 248px;
    height: 198px;
    background-position: -255px -407px;
  }
  .ECHT-NAAR-SLECHT0035 {
    width: 248px;
    height: 198px;
    background-position: -507px -407px;
  }
  .ECHT-NAAR-SLECHT0036 {
    width: 248px;
    height: 198px;
    background-position: -759px -407px;
  }
  .ECHT-NAAR-SLECHT0037 {
    width: 248px;
    height: 198px;
    background-position: -1011px -407px;
  }
  .ECHT-NAAR-SLECHT0038 {
    width: 248px;
    height: 198px;
    background-position: -1263px -407px;
  }
  .ECHT-NAAR-SLECHT0039 {
    width: 248px;
    height: 198px;
    background-position: -1515px -407px;
  }
  .ECHT-NAAR-SLECHT0040 {
    width: 248px;
    height: 198px;
    background-position: -1767px -407px;
  }
  .ECHT-NAAR-SLECHT0041 {
    width: 248px;
    height: 198px;
    background-position: -2019px -407px;
  }
  .ECHT-NAAR-SLECHT0042 {
    width: 248px;
    height: 198px;
    background-position: -3px -609px;
  }
  .ECHT-NAAR-SLECHT0043 {
    width: 248px;
    height: 198px;
    background-position: -255px -609px;
  }
  .ECHT-NAAR-SLECHT0044 {
    width: 248px;
    height: 198px;
    background-position: -507px -609px;
  }
  .ECHT-NAAR-SLECHT0045 {
    width: 248px;
    height: 198px;
    background-position: -759px -609px;
  }
  .ECHT-NAAR-SLECHT0046 {
    width: 248px;
    height: 198px;
    background-position: -1011px -609px;
  }
  .ECHT-NAAR-SLECHT0047 {
    width: 248px;
    height: 198px;
    background-position: -1263px -609px;
  }
  .ECHT-NAAR-SLECHT0048 {
    width: 248px;
    height: 198px;
    background-position: -1515px -609px;
  }
  .ECHT-NAAR-SLECHT0049 {
    width: 248px;
    height: 198px;
    background-position: -1767px -609px;
  }
  .ECHT-NAAR-SLECHT0050 {
    width: 248px;
    height: 198px;
    background-position: -2019px -609px;
  }
}
